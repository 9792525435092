import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import InputField from './../../elements/Input';
import RadioBox from './../../elements/Radiobox';
import CheckBox from './../../elements/CheckBox';
import Select from 'react-select';
import Button from './../../elements/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DateFormate from 'dateformat';
// import moment from 'moment';//need to remove
import secureStorage from './../../../config/encrypt';
import { PASSWORD_VALIDATION, MOBILE_VALIDATION, COUNTRY_CODE } from './../../../config/constant';
import {CONF_VAL} from './../../../config/constant';
import MasterService from '../../../service/MasterService';
import ModalPopup from '../../elements/Popup';
import ViewHistory from './ViewHistory';
import { BursaDetails ,REACT_APP_TENANT } from './../../../config/constant';
class BasicDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: (props.basic_details) ? false : true,
            basic_details: props.basic_details || {},
            owner_details: props.owner_details || {},
            dealership_list: props.dealership_list,
            // dealer_hash_id:props.dealer_hash_id || null,
            urlprefix: props.urlprefix || null,
            dealership_list_filtered: props.dealership_list[props.basic_details.parent_dealer_type],
            permission: props.permission || {},
            used_bursa_quota:props.used_bursa_quota || 0,
            errors: {},
            responseMsg: null,
            startDate: new Date(),
            disabledpassword: (props.basic_details && props.basic_details.dealer_id_hash) ? true : false,
            getLanguagelist: props.getLanguagelist,
            getAccountManagerList: props.getAccountManagerList,
            dealerPremiumTypeList: props.dealerPremiumTypeList,
            stockCategoryTypeList: props.stockCategoryTypeList,
            EditFlag: (props.basic_details && props.basic_details.dealer_id_hash) ? true : false, 
            previous_basic_details : this.props.basic_details,
            previous_owner_details : this.props.owner_details,
            prevBasicDetailsSet : false,
            prevOwnerDetailsSet : false,
            historyPopup : false,
            role_access: props.role_access || [],
            closingBalance: props.closingBalance || 1
        }

    }

    closeModal = () => {
        this.setState({ historyPopup: false });
    }

    handleHistory = () => {
        this.setState({historyPopup : true});
    }

    newPasswordEnable = (status) => {
        this.setState({ disabledpassword: status });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            getLanguagelist: nextProps.getLanguagelist,
            getAccountManagerList: nextProps.getAccountManagerList,
            dealerPremiumTypeList: nextProps.dealerPremiumTypeList,
            stockCategoryTypeList: nextProps.stockCategoryTypeList,
            role_access: nextProps.role_access || [],
            disabledpassword: (nextProps.basic_details && nextProps.basic_details.dealer_id_hash) ? true : false,
            loading: nextProps.loading,
            permission: nextProps.permission,
            basic_details: {
                ...nextProps.basic_details,
                is_bursa : nextProps.basic_details && nextProps.basic_details.is_bursa ? nextProps.basic_details.is_bursa : 0
            },
            // dealer_hash_id: nextProps.dealer_hash_id,
            urlprefix: nextProps.urlprefix,
            owner_details: nextProps.owner_details,
            dealership_list: nextProps.dealership_list,
            used_bursa_quota:nextProps.used_bursa_quota || 0,
            responseMsg: nextProps.responseMsg,
            dealership_list_filtered: nextProps.dealership_list_filtered,
            EditFlag: (nextProps.basic_details && nextProps.basic_details.dealer_id_hash) ? true : false,
            closingBalance: nextProps.closingBalance
        });
    }

    validForm = () => {
        let reg =  /^\d+$/;
        let fields = this.state.basic_details;
        let fields_onwer = this.state.owner_details;
        let formIsValid = true;
        let errors = { ...this.state.errors };
        if (!fields["organization"]) {
            errors['organization'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.organization');
            formIsValid = false;
        }
        if (!fields["dealership_email"]) {
            errors['dealership_email'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_email');
            formIsValid = false;
        }
        if (!fields["premium_code"]) {
            errors['premium_code'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.premium_code');
            formIsValid = false;
        }
        if (!fields["dealership_contact"]) {
            errors['dealership_contact'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone_'+COUNTRY_CODE)
            formIsValid = false;
        } else if (!MOBILE_VALIDATION.test(fields["dealership_contact"])) {
            formIsValid = false;
            errors["dealership_contact"] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone_'+COUNTRY_CODE);
        }
        if (!fields_onwer["name"]) {
            errors['name'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.owner_name');
            formIsValid = false;
        }
        if (!fields_onwer["email"]) {
            errors['email'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_email');
            formIsValid = false;
        }
        if (!fields["relation_dealer"]) {
            errors['relation_dealer'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.relation_dealer');
            formIsValid = false;
        }
        if(!fields_onwer["whatsapp_number"]){
            errors['whatsapp_number'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone_'+COUNTRY_CODE)
            formIsValid = false;
        }
        else if (!MOBILE_VALIDATION.test(fields_onwer["whatsapp_number"])) {
            formIsValid = false;
            errors["whatsapp_number"] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone_'+COUNTRY_CODE);
        }
        if (!fields_onwer["mobile"]) {
            errors['mobile'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone_'+COUNTRY_CODE)
            formIsValid = false;
        } else if (!MOBILE_VALIDATION.test(fields_onwer["mobile"])) {
            formIsValid = false;
            errors["mobile"] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone_'+COUNTRY_CODE);
        }
        else if (fields_onwer["password"] && ('' + fields_onwer["password"]).match(PASSWORD_VALIDATION) == null) {
            formIsValid = false;
            errors["password"] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.weak_password');
        }
        if (!fields["dealer_type_ids"] || fields["dealer_type_ids"].length == 0) {
            errors['dealer_type_ids'] = true;
            formIsValid = false;
        }
        if (!fields["stock_category"] || fields["stock_category"].length == 0) {
            errors['stock_category'] = true;
            formIsValid = false;
        }

        if(fields['is_bursa'] === null || fields['is_bursa'] === undefined || fields['is_bursa'] === ''){
            errors['is_bursa'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.is_bursa');
            formIsValid = false;
        }

        if(fields['is_bursa'] === 1 && !fields['bursa_name']){
            errors['bursa_name'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.bursa_name')
            formIsValid = false;
        }

        if(fields['is_bursa'] === 1 && !fields['bursa_quota']){
            errors['bursa_quota'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.bursa_quota')
            formIsValid = false;
        }
        if (fields['is_bursa'] === 1 && ( fields['bursa_quota'] > 10000 || fields['bursa_quota'] < 1 )) {
            errors['bursa_quota'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.bursa_quota_range_allow')
            formIsValid = false;
        }
        if (fields['is_bursa'] === 1 && fields['bursa_quota']) {
            if (!reg.test(fields['bursa_quota'])) {
                errors['bursa_quota'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.bursa_quota_only_number')
                formIsValid = false;
            }
        }
        if(this.state.used_bursa_quota > fields['bursa_quota']){
            errors['bursa_quota'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.bursa_quota_err_more_than_used')
            formIsValid = false;
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    checkIfDetailChanged(basic_details, previous_basic_details, owner_details, previous_owner_details) {
        let changed_details = {
            dealer_id : basic_details.id,
            dealership_name: "",
            dealership_contact_no: "",
            dealership_email: "",
            owner_name: "",
            owner_email: "",
            owner_contact_no: "",
            // is_priority_dealer:"0",
            // is_dealer_subscribe_for_dif:"0"
        }

        if (owner_details && previous_owner_details && owner_details.name !== previous_owner_details.name) {
            changed_details.owner_name = owner_details.name;
        }

        if (owner_details && previous_owner_details && owner_details.email !== previous_owner_details.email) {
            changed_details.owner_email = owner_details.email;
        }

        if (owner_details && previous_owner_details && owner_details.mobile !== previous_owner_details.mobile) {
            changed_details.owner_contact_no = owner_details.mobile;
        }

        if (basic_details && previous_basic_details && basic_details.organization !== previous_basic_details.organization) {
            changed_details.dealership_name = basic_details.organization;
        }

        if (basic_details && previous_basic_details && basic_details.dealership_email !== previous_basic_details.dealership_email) {
            changed_details.dealership_email = basic_details.dealership_email;
        }

        if (basic_details && previous_basic_details && basic_details.dealership_contact !== previous_basic_details.dealership_contact) {
            changed_details.dealership_contact_no = basic_details.dealership_contact;
        }

        // if (basic_details && previous_basic_details && basic_details.is_dealer_subscribe_for_dif !== previous_basic_details.is_dealer_subscribe_for_dif) {
        //     changed_details.is_dealer_subscribe_for_dif = basic_details.is_dealer_subscribe_for_dif;
        // }

        // if (basic_details && previous_basic_details && basic_details.is_priority_dealer !== previous_basic_details.is_priority_dealer) {
        //     changed_details.is_priority_dealer = basic_details.is_priority_dealer;
        // }

        return changed_details;
    }

    checkDetails(details) {
        for (let key in details) {
            if (key !== 'dealer_id' && details[key].trim() !== "") {
                return true;
            }
        }
        return false;
    }

    componentDidUpdate(prevProps) {
        if (!this.state.prevBasicDetailsSet && this.props.basic_details && this.props.basic_details.organization) {
            this.setState({ previous_basic_details: JSON.parse(JSON.stringify(this.props.basic_details)), prevBasicDetailsSet: true });
        }

        if (!this.state.prevOwnerDetailsSet && this.props.owner_details && this.props.owner_details.name) {
            this.setState({ previous_owner_details: JSON.parse(JSON.stringify(this.props.owner_details)), prevOwnerDetailsSet: true });
        }
    }



    saveBasicDetail = (event) => {
        console.log("this.state.basic_details",this.state.basic_details);
        event.preventDefault();
        if (this.validForm()) {
            if (typeof this.props.onClick === 'function') {
                this.props.onClick(event, this.state.owner_details, this.state.basic_details);
            }
            let changed_details = this.checkIfDetailChanged(this.state.basic_details, this.state.previous_basic_details, this.state.owner_details, this.state.previous_owner_details);
            if(this.state.basic_details && this.state.basic_details.id && this.checkDetails(changed_details)){
                MasterService.post('/dealer/dealer/save_detail_history', changed_details)
                .then(response => {
                    this.setState({
                        previous_basic_details: {...this.state.basic_details},
                        previous_owner_details: {...this.state.owner_details}
                    });
                })
                .catch(error => console.log(error))
            }

        } else {
            toast.error(this.props.t('dealerDetails.Basic_Details_master.validation_texts.fill_required_fields'));
        }
    }

    handleLeadOptionChange = (sname, oValue) => {
        let basic_details = this.state.basic_details;
        const errors = this.state.errors;
        this.setState({ basic_details });
        if (sname == 'premium_code') {
            basic_details['premium_code'] = oValue.code;
            delete errors[sname];
        } else {
            if (oValue == null)
                basic_details[sname] = 0;
            else
                basic_details[sname] = (oValue.id) != null ? oValue.id : oValue.target.value;
        }
        if(sname === 'is_bursa'){
            basic_details['is_bursa'] = oValue.id
            delete errors['is_bursa'];
        }
        this.setState({
            basic_details 
        });
    }

    handleOptionChange = (sname, oValue) => {
        let basic_details = this.state.basic_details;
        const errors = this.state.errors;
        basic_details[sname] = oValue.code;
        delete errors[sname];
        this.setState({
            basic_details 
        });
    }


    handleInputChange = (datefield, event) => {
        // alert(datefield);
        let basic_details = this.state.basic_details;
        let owner_details = this.state.owner_details;
        const errors = this.state.errors;
        //alert(event.target.name);
        event.target.name = datefield;
        delete errors[event.target.name]
        if (event.target.name == 'organization') {
            basic_details['dealership_name'] = event.target.value;
        }
        if (event.target.name == 'name' || event.target.name == 'email' ||
            event.target.name == 'mobile'|| event.target.name == 'whatsapp_number' || event.target.name == 'password') {
            owner_details[event.target.name] = event.target.value;
        } else {
            basic_details[event.target.name] = event.target.value;
        }
        if (event.target.value != '') {
            if (event.target.name == 'email' || event.target.name == 'dealership_email') {
                // eslint-disable-next-line no-useless-escape
                let regexp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (regexp.test(event.target.value)) {
                    delete errors[event.target.name];
                } else {
                    errors[event.target.name] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_email')
                }
            }
            else if (event.target.name == 'organization') {
                delete errors[event.target.name];
            }
            else if (event.target.name == 'name' && event.target.value != '') {
                delete errors[event.target.name];
            }
            else if (event.target.name == 'dealership_contact' || event.target.name == 'mobile' || event.target.name == 'whatsapp_number') {
                if (!MOBILE_VALIDATION.test(event.target.value)) {
                    errors[event.target.name] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.enter_valid_phone_'+COUNTRY_CODE)
                } else {
                    delete errors[event.target.name];
                }

            }
            if (event.target.name == 'password') {
                let regexp = PASSWORD_VALIDATION;
                if (regexp.test(event.target.value)) {
                    delete errors[event.target.name];
                } else {
                    errors[event.target.name] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.weak_password')
                }
            }
            if(event.target.name == 'bursa_name'){
                basic_details['bursa_name'] = event.target.value;
                delete errors[event.target.name];
            }
            if(event.target.name == 'bursa_quota'){
                basic_details['bursa_quota'] = event.target.value;
                delete errors[event.target.name];
            }

        } else if (event.target.name == 'password') {
            errors[event.target.name] = "";
        } else {
            if(event.target.name != 'whatsapp_number'){
                errors[event.target.name] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.please_enter') + event.target.name.replace("_", " ");
            }
        }

        this.setState({ basic_details, errors, owner_details }, () => {
            //console.log(this.state.basic_details)
            //console.log(this.state.errors)
        });

    }

    handlechecked = (code) => event => {
        //let value = event.target.value;
        //alert(code);
        let value = code;
        let status = true;
        if (event.target.checked) {
            status = true;
        } else {
            status = false;
        }
        let classname = 'dealer_type_' + value;
        let chkbox = document.querySelectorAll('.' + classname).length;

        var basic_details_new2 = { ...this.state.basic_details }
        if (!basic_details_new2.dealer_type_ids) {
            basic_details_new2.dealer_type_ids = [];
        }

        for (let i = 0; i < chkbox; i++) {
            let idname = 'dealer_type_' + value + '_' + i;
            let elements = document.getElementById(idname);

            if (elements) {
                elements.checked = status;
                let chkval = parseInt(elements.value);
                if (status) {
                    if (!basic_details_new2.dealer_type_ids.includes(chkval)) {
                        basic_details_new2.dealer_type_ids.push(chkval);
                    }

                } else {
                    var index = basic_details_new2.dealer_type_ids.indexOf(chkval);
                    if (index !== -1) basic_details_new2.dealer_type_ids.splice(index, 1);
                }
            }

        }

        let is_dealer_subscribe_for_dif = "0"
        if(event.target.name == 'is_dealer_subscribe_for_dif'){
            is_dealer_subscribe_for_dif = event.target.checked ? "1" : "0"
        }
        basic_details_new2.is_dealer_subscribe_for_dif = is_dealer_subscribe_for_dif

        this.setState({ basic_details: basic_details_new2 })

        //this.setState({dealer_type_ids:dealer_type_ids})

    }


    handleCategorychecked  =  (field) => event => {
        console.log("field",field);
        if (field == 'stock_category') {
            console.log("field",field);
            let chkval = parseInt(event.target.value);
            console.log("chkval",chkval);

            let errors = { ...this.state.errors };
            let basic_details = { ...this.state.basic_details };
            if (!basic_details.stock_category) {
                basic_details.stock_category = [];
            }
            if (!basic_details.stock_category.includes(chkval)) {
                // console.log('not');
                basic_details.stock_category.push(chkval);
            } else {
                 console.log('yes');
                let index_db = basic_details.stock_category.indexOf(chkval);
                basic_details.stock_category.splice(index_db, 1);
            }
            if (basic_details.stock_category.length == 0)
                errors['stock_category'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.this_field_required');
            else
                delete errors['stock_category'];

            console.log(basic_details.stock_category, "errors['stock_category']", errors['stock_category']);

            this.setState({ basic_details: basic_details, errors })

        }
    }


    handledate = (datefield, date) => {
        if (datefield == 'dealer_type_ids') {
            let chkval = parseInt(date.target.value);

            let errors = { ...this.state.errors };
            let basic_details = { ...this.state.basic_details };
            if (!basic_details.dealer_type_ids) {
                basic_details.dealer_type_ids = [];
            }
            if (!basic_details.dealer_type_ids.includes(chkval)) {
                // console.log('not');
                basic_details.dealer_type_ids.push(chkval);
            } else {
                // console.log('yes');
                let index_db = basic_details.dealer_type_ids.indexOf(chkval);
                basic_details.dealer_type_ids.splice(index_db, 1);
            }
            if (basic_details.dealer_type_ids.length == 0)
                errors['dealer_type_ids'] = this.props.t('dealerDetails.Basic_Details_master.validation_texts.this_field_required');
            else
                delete errors['dealer_type_ids'];

            console.log(basic_details.dealer_type_ids, "errors['dealer_type_ids']", errors['dealer_type_ids']);

            this.setState({ basic_details: basic_details, errors })

        } else if (datefield == 'parent_dealer_type') {
            let basic_details = { ...this.state.basic_details };
            let dealership_list = { ...this.state.dealership_list };
            let chkval = parseInt(date.target.value);
            let indexval = 0;
            if (chkval == 3) {
                indexval = 1;
            } else if (chkval == 4) {
                indexval = 2;
            }
            basic_details.dealer_type_ids = [];
            let dealership_list_filtered = dealership_list[indexval];
            basic_details.parent_dealer_type = chkval;
            this.setState({ dealership_list_filtered: dealership_list_filtered, basic_details: basic_details }, () => {
                // console.log(this.state.dealership_list_filtered)
            })
        } else if (datefield == 'is_priority_dealer') {
            let basic_details = { ...this.state.basic_details };
            basic_details.is_priority_dealer = date.target.value;
            this.setState({ basic_details }, () => {
            })
        }else {
            var owner_details = { ...this.state.owner_details }
            if (date != null)
                owner_details[datefield] = new Date(date);
            else
                owner_details[datefield] = '';
            this.setState({ owner_details }, () => {
                // console.log(this.state.owner_details);
            })
        }
    }
    render() {
        const { role_access } = this.state;
        let basicinfo={};
        let ownerinfo, dealership_list;
        basicinfo = this.state.basic_details;
        ownerinfo = this.state.owner_details;
        dealership_list = this.props.dealership_list;
        //console.log("HHHHHHHHHHHHHHHHHH00----",basicinfo)

        let dealership_list_filtered = this.state.dealership_list_filtered;
        let dealershiptype_dealer = dealership_list[0];
        const disableOwnerInfo = basicinfo && basicinfo.is_dealer_wallet_subscribed && basicinfo.is_dealer_wallet_subscribed === 1 && this.state.closingBalance === 1;

        if (dealership_list_filtered) {
            //   console.log('dealershiptype_dealer_filter',dealershiptype_dealer)
            dealershiptype_dealer = dealership_list_filtered;
        }
        else {

            if (basicinfo.parent_dealer_type) {
                if (basicinfo.parent_dealer_type == '3' || basicinfo.parent_dealer_type == 3) {
                    dealershiptype_dealer = dealership_list[1];
                } else if (basicinfo.parent_dealer_type == '4' || basicinfo.parent_dealer_type == 4) {
                    dealershiptype_dealer = dealership_list[2];
                }
            }
        }

        if (!basicinfo.premium_code) {
            basicinfo.premium_code = '';
        }

        if (!basicinfo.is_priority_dealer) {
            basicinfo.is_priority_dealer = "0";
        }
        let role,ac_manager_disable_flag=true;
        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');
            if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
                role = loginResponse.data.user_data.role;
            }
        }
        if (!basicinfo.dealer_type_ids && !this.state.EditFlag) {
            basicinfo.dealer_type_ids = [1];
        }
        if (!basicinfo.stock_category && !this.state.EditFlag) {
            CONF_VAL.DEFAULT_CATEGORY_FLAG ? basicinfo.stock_category = [1, 3] : basicinfo.stock_category = [1];
        }
        if(role == 'admin'){
            ac_manager_disable_flag=false;
        }
       let { errors, getLanguagelist, dealerPremiumTypeList ,stockCategoryTypeList ,getAccountManagerList } = this.state; 
       let selectedDob_props , selectedDoj_props = {}
       if(ownerinfo.birth_date){
         selectedDob_props = {
                selected: new Date(ownerinfo.birth_date)
            }
        }
        if(ownerinfo.date_of_joining){
            selectedDoj_props = {
                selected: new Date(ownerinfo.date_of_joining)
            }
        }
       return (

            <div>
                <button className="btn pull-right " onClick={this.handleHistory}>{this.props.t('dealerDetails.Basic_Details_master.view_history')}</button>
                <h1>{this.props.t('dealerDetails.Basic_Details_master.Basic_Details')}
                    <span className="f12">
                        {
                            (basicinfo.gcd_code) ? <span> ({basicinfo.organization} - {basicinfo.gcd_code})</span> : ''
                        }
                    </span>
                    <span className="f12">
                        {
                            (basicinfo.vendor_id) ? <span> Vendor ID - {basicinfo.vendor_id}</span> : ''
                        }
                    </span>

                </h1>
                <form className={(this.state.loading) ? 'text-left os-animation animated fadeInDown loading' : 'text-left os-animation animated fadeInDown'} data-os-animation="fadeInDown" data-os-animation-delay="0s" method="post" id="basicdetail_form" onSubmit={this.saveBasicDetail}>

                    <div className="card">
                        <div className="card-heading"><h2 className="card-title">{this.props.t('dealerDetails.Basic_Details_master.Basic_Details')}</h2></div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-4">
                                    <InputField type="text" error={(errors.organization) ? errors.organization : ''} name="organization" id="dealership_name" placeholder={this.props.t('dealerDetails.Basic_Details_master.dealership_name_placeholder')} value={(this.state.basic_details.organization) ? this.state.basic_details.organization : ''} label={this.props.t('dealerDetails.Basic_Details_master.dealership_name_label')} onChange={this.handleInputChange.bind(this, 'organization')} required={true} />

                                </div>
                                <div className="col-sm-4">
                                    <InputField type="email" error={(errors.dealership_email) ? errors.dealership_email : ''} name="dealership_email" id="dealership_email" placeholder={this.props.t('dealerDetails.Basic_Details_master.dealership_email_placeholder')} value={(basicinfo.dealership_email) ? basicinfo.dealership_email : ''} label={this.props.t('dealerDetails.Basic_Details_master.dealership_email_label')} onChange={this.handleInputChange.bind(this, 'dealership_email')} required={true} />

                                </div>
                                <div className="col-sm-4">
                                    <InputField maxLength="12" type="tel" error={(errors.dealership_contact) ? errors.dealership_contact : ''} name="dealership_contact" id="dealership_contact_number" placeholder={this.props.t('dealerDetails.Basic_Details_master.dealership_contact_placeholder')} value={(basicinfo.dealership_contact) ? basicinfo.dealership_contact : ''} label={this.props.t('dealerDetails.Basic_Details_master.dealership_contact_label')} onChange={this.handleInputChange.bind(this, 'dealership_contact')} required={true} />
                                </div>
                            </div>
                            <div className="row">
                               {/* {getLanguagelist && getLanguagelist.length > 1 ?
                                <div className="form-field col-sm-4">
                                    <label>{this.props.t('dealerDetails.Basic_Employee_Details.language')}</label>
                                    <Select
                                        components={{ IndicatorSeparator:() => null }}
                                        id="default_lang_id"
                                        options={getLanguagelist}
                                        onChange={this.handleLeadOptionChange.bind(this, 'default_lang_id')}
                                        name="default_lang_id"
                                        placeholder={this.props.t('dealerDetails.Basic_Employee_Details.language')}
                                        value={getLanguagelist.filter(({ id }) => id === basicinfo.default_lang_id)}
                                        getOptionLabel={({ name }) => name}
                                        getOptionValue={({ id }) => id}
                                    />
                                </div>
                                : ''} */}

                                    {/* <div className="form-field col-sm-4">
                                        <label>{this.props.t('dealerDetails.Basic_Employee_Details.ac_manager')}</label>
                                        <Select
                                            components={{ IndicatorSeparator:() => null }}
                                            isDisabled={ac_manager_disable_flag}
                                            isClearable
                                            id="ac_manager_id"
                                            options={getAccountManagerList}
                                            onChange={this.handleLeadOptionChange.bind(this, 'ac_manager_id')}
                                            name="ac_manager_id"
                                            placeholder={this.props.t('dealerDetails.Basic_Employee_Details.ac_manager_placeholder')}
                                            value={getAccountManagerList.filter(({ id }) => id === basicinfo.ac_manager_id)}
                                            getOptionLabel={({ name }) => name}
                                            getOptionValue={({ id }) => id}
                                        />
                                    </div> */}
                               {REACT_APP_TENANT != "tri-id" ?
                                   <div className="col-sm-4">
                                       <InputField type="text" name="account_manager_name" id="account_manager_name" value={(this.state.basic_details.ac_manager_name) ? this.state.basic_details.ac_manager_name : ''} label={"A/c Manager"} disabled={role_access.includes('edit_account_manager') ? false : true} />
                                   </div> : ""}

                               {
                                   (role == 'admin') ?
                                       <div className="form-field col-sm-4">
                                           <label>{this.props.t('dealerDetails.Basic_Employee_Details.premium_type')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                           <Select
                                               components={{ IndicatorSeparator:() => null }}
                                               id="premium_code"
                                               options={dealerPremiumTypeList}
                                               onChange={this.handleLeadOptionChange.bind(this, 'premium_code')}
                                               name="premium_code"
                                               placeholder={this.props.t('dealerDetails.Basic_Employee_Details.premium_type_placeholder')}
                                               value={dealerPremiumTypeList && dealerPremiumTypeList.length ? dealerPremiumTypeList.filter(({ code }) => code === basicinfo.premium_code) : []}
                                               getOptionLabel={({ title }) => title}
                                               getOptionValue={({ code }) => code}
                                           />
                                           {
                                               (errors && errors.premium_code) ? <span className="error show">{errors.premium_code}</span> : ''
                                           }
                                       </div> : ''
                               }
                               {
                                   (role == 'admin') ?
                                    <div className="form-field col-sm-4">
                                        <label>{this.props.t('dealerDetails.Basic_Employee_Details.relation_dealer')} <span className="required" style={{ color: 'red' }}>*</span></label>
                                        <Select
                                            components={{ IndicatorSeparator:() => null }}
                                            id="relation_dealer"
                                            options={this.props.dealerRelationship}
                                            value={this.props.dealerRelationship.filter(({ code }) => code === basicinfo.relation_dealer)}
                                            onChange={this.handleOptionChange.bind(this, 'relation_dealer')}
                                            name="relation_dealer"
                                            placeholder={this.props.t('dealerDetails.Basic_Employee_Details.relation_dealer_placeholder')}
                                            getOptionLabel={({ title }) => title}
                                            getOptionValue={({ code }) => code}
                                        />
                                        {
                                               (errors && errors.relation_dealer) ? <span className="error show">{errors.relation_dealer}</span> : ''
                                        }
                                    </div>: ''
                               }
                               <div className="form-field col-sm-4">
                                   <label htmlFor="date_of_joining" className="">{this.props.t('dealerDetails.Basic_Details_master.owner_dateof_joining_label')}</label>
                                   <DatePicker showYearDropdown showMonthDropdown className="form-control" name="date_of_joining" id="date_of_joining" placeholder={this.props.t('dealerDetails.Basic_Details_master.owner_dateof_joining_placeholder')} label={this.props.t('dealerDetails.Basic_Details_master.owner_dateof_joining_label')} onChange={this.handledate.bind(this, 'date_of_joining')} autoComplete="off" {...selectedDoj_props} />
                               </div> 
                            </div>
                            {/* <div className="row">
                                <div className="col-sm-4">
                                    <label>{this.props.t('dealerDetails.Basic_Details_master.dealer_inventory_funding.label')} </label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-2">
                                    <div className="rc-inline">
                                      <CheckBox type="checkbox" name="is_dealer_subscribe_for_dif" id='is_dealer_subscribe_for_dif' value={this.state.basic_details.is_dealer_subscribe_for_dif} checked={(this.state.basic_details.is_dealer_subscribe_for_dif == "1") ? 'checked' : ''} label={this.props.t('dealerDetails.Basic_Details_master.dealer_inventory_funding.dif')} onChange={this.handlechecked('is_dealer_subscribe_for_dif')} />
                                     </div>
                                </div>
                            </div>
                            {
                                (this.state.basic_details.is_dealer_subscribe_for_dif == 1) ?

                            <div className="alert alert-default form-group">
                            <div className="row" style={(this.state.permission.dealerTypeViewOnly == true) ? { pointerEvents: 'none' } : null}>
                                <div className="col-sm-4 ">
                                <div className="rc-inline">
                                        <RadioBox type="radio" name="is_priority_dealer" id="pri_dealer" value="1" label={this.props.t('dealerDetails.Basic_Details_master.dealer_inventory_funding.pri_dealer')} checked={(basicinfo.is_priority_dealer == 1 )? true : false} onChange={this.handledate.bind(this, 'is_priority_dealer')} />
                                        <RadioBox type="radio" name="is_priority_dealer" id="non_pri_dealer" value="0" label={this.props.t('dealerDetails.Basic_Details_master.dealer_inventory_funding.non_pri_dealer')} checked={(basicinfo.is_priority_dealer == 0) ? true : false} onChange={this.handledate.bind(this, 'is_priority_dealer')} />                                                                    </div>
                                </div>
                            </div>
                            </div>: ''
                            } */}


                            {/* <div className="row" style={(this.state.permission.dealerTypeViewOnly == true) ? { pointerEvents: 'none' } : null}>
                                <div className="col-sm-4">
                                    <label>{this.props.t('dealerDetails.Basic_Details_master.dealership_type.label')} <span className="required" style={{ color: 'red' }}>*</span></label>
                                    <div className="rc-inline">
                                        <RadioBox type="radio" name="parent_dealer_type" id="dealer" value="1" label={this.props.t('dealerDetails.Basic_Details_master.dealership_type.dealer')} checked={(basicinfo.parent_dealer_type == 1 || (!basicinfo.parent_dealer_type)) ? true : false} onChange={this.handledate.bind(this, 'parent_dealer_type')} />
                                        <RadioBox type="radio" name="parent_dealer_type" id="dsa" value="3" label={this.props.t('dealerDetails.Basic_Details_master.dealership_type.dsa')} checked={(basicinfo.parent_dealer_type == 3) ? true : false} onChange={this.handledate.bind(this, 'parent_dealer_type')} />
                                    </div>
                                </div>
                            </div> */}

                            {
                            (role == 'admin') ?
                           <div className="row">
                               <div className="col-sm-2 form-group">
                                   <label>{this.props.t('dealerDetails.Basic_Details_master.Bursa_Details')} <span className="required" style={{ color: 'red' }}>*</span></label>
                                   <Select
                                       components={{ IndicatorSeparator:() => null }}
                                       id="is_bursa"
                                       options={BursaDetails}
                                       onChange={this.handleLeadOptionChange.bind(this, 'is_bursa')}
                                       name="bursa_details"
                                       placeholder={this.props.t('dealerDetails.Basic_Details_master.Bursa_Details')}
                                       value={BursaDetails.filter(({ id }) => id === this.state.basic_details.is_bursa)}
                                       getOptionLabel={({ label }) => label}
                                       getOptionValue={({ id }) => id}
                                       required={true}
                                   />
                                   {
                                       (errors && errors.is_bursa) ? <span className="error show">{errors.is_bursa}</span> : ''
                                   }
                               </div>
                               {
                                   this.state.basic_details && this.state.basic_details && this.state.basic_details.is_bursa ?
                                       <div className="col-sm-2">
                                           <InputField
                                               type="text"
                                               error={errors.bursa_name ? errors.bursa_name : ''}
                                               name="bursa_name"
                                               id="bursa_name"
                                               placeholder={this.props.t('dealerDetails.Basic_Details_master.Bursa_Name')}
                                               label={this.props.t('dealerDetails.Basic_Details_master.Bursa_Name')}
                                               value={this.state.basic_details.bursa_name}
                                               onChange={this.handleInputChange.bind(this, 'bursa_name')}
                                               required={this.state.basic_details.is_bursa === 1 ? true : false}
                                           />
                                       </div> : ""
                               }
                                       {
                                           this.state.basic_details && this.state.basic_details && this.state.basic_details.is_bursa ?
                                               <><div className="col-sm-2">
                                                   <InputField
                                                       type="text"
                                                       error={errors.bursa_quota ? errors.bursa_quota : ''}
                                                       name="bursa_quota"
                                                       id="bursa_quota"
                                                       placeholder={this.props.t('dealerDetails.Basic_Details_master.Bursa_Quota')}
                                                       label={this.props.t('dealerDetails.Basic_Details_master.Bursa_Quota')}
                                                       value={this.state.basic_details.bursa_quota}
                                                       onChange={this.handleInputChange.bind(this, 'bursa_quota')}
                                                       required={this.state.basic_details.is_bursa === 1 ? true : false}
                                                   />
                                               </div>
                                                   <div className="col-sm-2">
                                                       <br />
                                                       <b>Used: </b>{this.state.used_bursa_quota} <br />
                                                       <b>Remaining: </b>{(parseInt(this.state.basic_details.bursa_quota) - parseInt(this.state.used_bursa_quota))}
                                                   </div></> : ""
                                       }
                           </div>: "" }

                            {
                                (dealership_list.length > 0) ?

                                    <div className="alert alert-default form-group margin-15" style={(this.state.permission.dealerTypeViewOnly == true) ? { pointerEvents: 'none' } : null}>

                                        <div className="row">{dealershiptype_dealer.length}

                                            {
                                                (dealershiptype_dealer.dealer_types.length > 0) ?
                                                    dealershiptype_dealer.dealer_types.map((dealertype, k) =>
                                                        <div className="col-sm-6 " key={k + dealertype.name}>
                                                            <h6>{dealertype.name}</h6>
                                                            <div className="rc-inline">

                                                                {
                                                                    (dealertype.list.length > 0) ?

                                                                        <>

                                                                            <CheckBox type="checkbox" name="dealer_type[]" id={dealertype.code} value={dealertype.code} label={this.props.t('dealerDetails.Basic_Details_master.all')} data-key={dealertype.code} key={dealertype.code} onChange={this.handlechecked(dealertype.code)} />
                                                                            {dealertype.list.map((singletype, index) =>
                                                                                <CheckBox type="checkbox" name="dc_dealers_types_mapping[]" className={'dealer_type_' + dealertype.code}
                                                                                    id={'dealer_type_' + dealertype.code + '_' + index} value={singletype.id} label={singletype.name}
                                                                                    key={singletype.id} checked={(basicinfo.dealer_type_ids && basicinfo.dealer_type_ids.includes(singletype.id)) ? 'checked' : ''} onChange={this.handledate.bind(this, 'dealer_type_ids')}
                                                                                />
                                                                            )}
                                                                        </>


                                                                        : ''
                                                                }

                                                            </div>
                                                        </div>
                                                    ) : ''
                                            }

                                        </div>
                                    </div>

                                    : ''
                            }
                            {this.state.errors['dealer_type_ids'] &&
                                <div className="error show">{this.props.t('dealerDetails.Basic_Details_master.validation_texts.this_field_required')}</div>}

                            <div className="alert alert-default form-group" style={(this.state.permission.dealerTypeViewOnly == true) ? { pointerEvents: 'none' } : null}>
                                <div className="row">
                                    <div className="form-field col-sm-4">
                                        <label>{this.props.t('dealerDetails.Basic_Details_master.stock_type')} <span className="required" style={{ color: 'red' }}>*</span></label>
                                        {
                                            (stockCategoryTypeList.length > 0) ?
                                                <div className='rc-inline'>
                                                    {stockCategoryTypeList.map((category, index) =>
                                                        <CheckBox type="checkbox" name="dc_dealers_types_mapping[]"
                                                            id={'category_type_'+category.key} value={category.key} label={category.value}
                                                            key={category.key} checked={(basicinfo.stock_category && basicinfo.stock_category.includes(category.key)) ? 'checked' : ''} onChange={this.handleCategorychecked('stock_category')}
                                                        />
                                                    )}
                                                </div>

                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            {this.state.errors['stock_category'] &&
                                <div className="error show">{this.props.t('dealerDetails.Basic_Details_master.validation_texts.this_field_required')}</div>}

                            <h2 className="contentbreak">{this.props.t('dealerDetails.Basic_Details_master.owner_details')}</h2>
                            <div className="row">
                                <div className="col-sm-4">
                                    <InputField type="text" error={(errors.name) ? errors.name : ''} name="name" id="owner_name" placeholder={this.props.t('dealerDetails.Basic_Details_master.owner_name_placeholder')} value={ownerinfo.name} label={this.props.t('dealerDetails.Basic_Details_master.owner_name_label')} onChange={this.handleInputChange.bind(this, 'name')} required={true} />
                                </div>
                                <div className="col-sm-4">
                                    <InputField disabled={role_access.includes('edit_owner_email') ? false : disableOwnerInfo} type="email" error={(errors.email) ? errors.email : ''} name="email" id="owner_email" placeholder={this.props.t('dealerDetails.Basic_Details_master.owner_placeholder')} value={ownerinfo.email} label={this.props.t('dealerDetails.Basic_Details_master.owner_email_label')} onChange={this.handleInputChange.bind(this, 'email')} required={true}/>
                                </div>
                                <div className="col-sm-4">
                                   <InputField disabled={role_access.includes('edit_owner_mobile_no')  ? false : disableOwnerInfo} type="tel" error={(errors.mobile) ? errors.mobile : ''} name="mobile" id="owner_contact_number" placeholder={this.props.t('dealerDetails.Basic_Details_master.owner_contact_number_placeholder')} value={ownerinfo.mobile} label={this.props.t('dealerDetails.Basic_Details_master.owner_contact_number_label')} onChange={this.handleInputChange.bind(this, 'mobile')} required={true} maxLength="12" />
                                </div>
                                {/* <div className="col-sm-4 edit-mobile">
                                    <InputField type="password" disabled={this.state.disabledpassword} error={(errors.password) ? errors.password : ''} name="password" id="password" placeholder={this.props.t('dealerDetails.Basic_Details_master.owner_password_placeholder')} value={ownerinfo.password} label={this.props.t('dealerDetails.Basic_Details_master.owner_password_label')} onChange={this.handleInputChange.bind(this, 'password')} RestrictOnPaste={1} RestrictOnCopy={1}/>
                                    {(this.state.disabledpassword)
                                        ? <a className="edit-mobile-link" data-flag="false" onClick={this.newPasswordEnable.bind(this, false)}>{this.props.t('dealerDetails.Basic_Details_master.Enable_New_Password')}</a>
                                        : <a className="edit-mobile-link" data-flag="false" onClick={this.newPasswordEnable.bind(this, true)}>{this.props.t('dealerDetails.Basic_Details_master.Disabled_New_Password')}</a>}
                                </div> */}
                                {/* <div className="col-sm-4"><InputField type="text" name="dob" id="dob" placeholder="DOB" value={ownerinfo.birth_date} label="Date of Birth" onChange={this.handleChange}/></div> */}
                                <div className="col-sm-4">
                                    <label htmlFor="birth_date" className="">{this.props.t('dealerDetails.Basic_Details_master.owner_dateof_birth_label')}</label>
                                    <DatePicker showYearDropdown showMonthDropdown maxDate={new Date()} className="form-control"  name="birth_date" id="birth_date" placeholder="DOB" label={this.props.t('dealerDetails.Basic_Details_master.owner_dateof_birth_placeholder')} onChange={this.handledate.bind(this, 'birth_date')} autoComplete="off" {...selectedDob_props}/>
                                </div>
                                <div className="col-sm-4">
                                    <InputField disabled={disableOwnerInfo} type="tel" error={(errors.whatsapp_number) ? errors.whatsapp_number : ''} name="whatsapp_number" id="whatsapp_number" placeholder={this.props.t('dealerDetails.Basic_Details_master.owner_whatsapp_number_placeholder')} value={ownerinfo.whatsapp_number} label={this.props.t('dealerDetails.Basic_Details_master.owner_whatsapp_number_label')} onChange={this.handleInputChange.bind(this, 'whatsapp_number')} required={true}  maxLength="12" />
                                </div>
                                {/* <div className="col-sm-4">
                                    <label htmlFor="date_of_joining" className="">{this.props.t('dealerDetails.Basic_Details_master.owner_dateof_joining_label')}</label>
                                    <DatePicker showYearDropdown showMonthDropdown className="form-control"  name="date_of_joining" id="date_of_joining" placeholder={this.props.t('dealerDetails.Basic_Details_master.owner_dateof_joining_placeholder')} label={this.props.t('dealerDetails.Basic_Details_master.owner_dateof_joining_label')} onChange={this.handledate.bind(this, 'date_of_joining')} autoComplete="off" {...selectedDoj_props}/>
                                </div>                                 */}
                            </div>
                            <div className="row">                                
                                <div className="col-sm-4">                                
                                 <label htmlFor="Created_Date" className="">Created Date:{DateFormate(ownerinfo.created_date, 'dS mmm yyyy h:MM TT')}</label> 
                                </div>
                                </div>
                        </div>

                        <div className="card-footer text-center">
                            {
                               role_access.includes("update_dealer_basic_details") ? <Button btnClass="btn btn-primary mrg-r30" title={this.props.t('dealerDetails.Basic_Details_master.save')} type="submit" /> : ''
                            }
                            
                            {
                                (basicinfo && basicinfo.id && basicinfo.id > 0) ? <NavLink to={this.props.nextpage} className="btn btn-default">{this.props.t('dealerDetails.Outlet_Details_master.Next')} <i className="ic-arrow_forward"></i></NavLink> : ''
                            }

                        </div>
                    </div>
                </form>
               {this.state.historyPopup === false ? null : <ModalPopup id="view_history" className="modal ViewHistory data-table" title={'View History'} modalClose={this.closeModal}>
                   {basicinfo && basicinfo.id && <ViewHistory  histoy_dealer_id={basicinfo.id}  StateList={this.props.StateList} cityAllList={this.props.cityAllList} />}
               </ModalPopup>}
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(BasicDetails));