import React, { Component } from 'react';
import InputField from '../elements/InputField';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
class SearchSalesUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: {},
    };
  }

  handleOptionChange = (sname, ovalue) => {
    let filterData = this.state.filterData;
    filterData[sname] = ovalue.value != null ? ovalue.value : ovalue.target.value;
    this.setState({ filterData });
    if (typeof this.props.optionChange === 'function') {
      this.props.optionChange(sname, ovalue);
    }
  };
 

  componentWillMount() {
    if (sessionStorage.getItem('userData')) {
      this.setState({ redirect: true });
    }
  }
  submitFilterForm = (event) => {
    event.preventDefault();
    if (typeof this.props.onSubmitFilter === 'function') {
      this.props.onSubmitFilter(event);
    }
  };

  resetFilterForm = (event) => {
    window.location.reload(false);
  };
  render() {
    // const { searchByList, cityAllList, dcSkusList, dealerCountStatusDetail } = this.props;//all var are not in use
    // const { filterData } = this.state;//var not in use
    return (
      <div className="search-wrap">
        <form onSubmit={this.submitFilterForm}>
          <div className="row">
            <div className="col-sm-2 col-md-2">
              <InputField
                inputProps={{
                  id: 'search_text',
                  type: 'text',
                  placeholder: this.props.t('Listing.Search_Dealer.name'),
                  name: 'search_text',
                  autocompleate: 'off',
                  label: this.props.t('Listing.Search_Dealer.Search_By_Placeholder'),
                }}
                onChange={this.handleOptionChange.bind(this, 'name')}
              />
            </div>

            <div className="col-sm-2 col-md-2">
              <InputField
                inputProps={{
                  id: 'search_text',
                  type: 'text',
                  placeholder: this.props.t('Listing.Search_Dealer.email'),
                  name: 'search_text',
                  autocompleate: 'off',
                  label: '',
                }}
                onChange={this.handleOptionChange.bind(this, 'email')}
              />
            </div>

            <div className="col-sm-2 col-md-3">
              <label></label>
              <div>
                <button type="submit" className="btn btn-primary mrg-r10">
                  {this.props.t('Listing.Search_Dealer.Search')}
                </button>
                <button type="reset" className="btn btn-default" onClick={this.resetFilterForm}>
                  {this.props.t('Listing.Search_Dealer.Reset')}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData }),
  };
};
export default withTranslation('sales_support_users')(connect(mapStateToProps, mapDispatchToProps)(SearchSalesUsers));
