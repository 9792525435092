import React, { Component } from 'react';
import SearchSalesUsers from './searchSalesUsers.js';
import { Redirect } from 'react-router-dom';
import SearchResult from './SearchResult';
import secureStorage from '../../config/encrypt';
import { connect } from 'react-redux';
import MasterService from '../../service/MasterService';
import { withTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';

class SalesSupportUsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageloading: true,
      dealerListData: [],
      searchByList: [],
      cityAllList: [],
      dcSkusList: [],
      dealerCountStatusDetail: {},
      page: 0,
      filterData: {
        status: '1',
      },
      redirect: false,
      mappingCsvData: [],
      dealerPremiumTypeList: [],
    };
    this.onScroll = this.onScroll.bind(this);
    this.getDealerList = this.getDealerList.bind(this);
    this.getDealerCountStatusDetail = this.getDealerCountStatusDetail.bind(this);
    this.flag = true;
    this.csvLink = React.createRef();
  }
  updateReduxData = async () => {
    if (secureStorage.getItem('loginUserInfo')) {
      var loginResponse = secureStorage.getItem('loginUserInfo');
      this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
      this.afterLoginSetState(loginResponse);
    } else {
      this.setState({ redirect: true });
    }
  };
  componentDidMount = async () => {
    await this.updateReduxData();
    this.onScroll('Mounter');
    window.addEventListener('scroll', this.onScroll);
    await this.getSearchByList();
    await this.getDealerCountStatusDetail();
    await this.getLanguageList();
  };

  getLanguageList() {
    let self = this;
    MasterService.getMasters(['dealer_premium_type'], { headers: { Authorization: self.props.auth_token } })
      .then((response) => {
        if (response.status == 200 && response.data.status == 200) {
          self.setState(
            {
              dealerPremiumTypeList: response.data.data.dealer_premium_type,
            },
            () => {}
          );
        } else if (response.status == 401 && response.data.status == 401) {
          self.setState({ redirect: true });
        }
      })
      .catch((error) => {});
  }

  afterLoginSetState = (loginResponse) => {
    let role = (loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role) || null;
    if (role != 'admin') {
      this.setState({ redirect: '/' });
    }
  };
  handleOptionChange = (sname, oValue) => {
    let filterData = this.state.filterData;
    filterData[sname] = oValue.value != null ? oValue.value : oValue.target.value;
    this.setState({
      filterData,
    });
  };

  handleStatusClick = async (sname, ovalue) => {
    let filterData = this.state.filterData;
    if (sname == 'active') {
      filterData['status'] = '1';
    } else if (sname == 'deleted') {
      filterData['status'] = '2';
    }
    this.setState({ filterData });
    // eslint-disable-next-line react/no-direct-mutation-state
    // this.state.page = 1;//need to remove
    this.setState({ page: 1 });

    this.getDealerList();
    this.getDealerCountStatusDetail();
  };
  onScroll(event) {
    if (window.innerHeight + window.scrollY > document.body.offsetHeight - 300) {
      if (this.flag) {
        let current_page = this.state.page;
        let loading = false,
          pageloading = false;
        if (current_page == 0) {
          pageloading = true;
        } else {
          loading = true;
        }
        this.setState({ page: current_page + 1, loading: loading, pageloading: pageloading }, () => {
          this.getDealerList();
        });
      }
      this.flag = false;
    }
  }

  getDealerList() {
    var thisObj = this;
    //this.setState({loading:true})
    MasterService.post('dealer/dealer/sales_users_list', { page_no: this.state.page, ...this.state.filterData })
      .then(function (response) {
        thisObj.flag = false;
        thisObj.setState({ loading: false, pageloading: false });
        if (response.data.status == 200) {
          if (response.data.data.length) {
            if (thisObj.state.page <= 1) {
              thisObj.setState({ dealerListData: response.data.data, loading: false, pageloading: false }, () => {
                thisObj.flag = true;
              });
            } else {
              thisObj.setState(
                {
                  dealerListData: thisObj.state.dealerListData.concat(response.data.data),
                  loading: false,
                  pageloading: false,
                },
                () => {
                  thisObj.flag = true;
                }
              );
            }
          } else {
            if (thisObj.state.page <= 1) {
              thisObj.setState({ dealerListData: response.data.data });
            }
          }
        }
      })
      .catch(function (response) {
        thisObj.setState({ loading: false, pageloading: false });
      });
  }

  submitFilterForm = () => {
    // this.state.page = 1;//need to remove
    this.setState({ page: 1 });
    this.setState({ pageloading: true });
    this.getDealerList();
    this.getDealerCountStatusDetail();
  };

  pageLoadingActivate = (value) => {
    this.setState({ pageloading: value });
  };

  getSearchByList() {
    // var parentObj = this;
    // var thisObj = this;
    MasterService.get('dealer/dealer/search-by-list')
      .then((searchResult) => {
        this.flag = false;
        if (searchResult && searchResult.data && searchResult.data.status == 200 && searchResult.data.data) {
          this.setState({
            searchByList: searchResult.data.data,
          });
        }
      })
      .catch(function (searchError) {
        console.error('searchError', searchError);
      });
  }
 
  getDealerCountStatusDetail() {
    var parentObj = this;
    this.setState({ pageloading: true });
    MasterService.post('dealer/dealer/count_sales_status', { page_no:this.state.page, ...this.state.filterData })
      .then(function (response) {
        if (response.status == 200 && response.data.status == 200) {
          parentObj.setState({ pageloading: false });
          parentObj.setState(
            {
              dealerCountStatusDetail: response.data.data || {},
            },
            () => {}
          );
        }
      })
      .catch(function (response) {});
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={'/login'} />;
    }
    return (
      <div className={this.state.pageloading ? 'container-fluid loading' : 'container-fluid'}>
        <div className="addStock">
          <h1>{this.props.t('Listing.sales_Listing')}</h1>
          <SearchSalesUsers
            dcSkusList={this.state.dcSkusList}
            cityAllList={this.state.cityAllList}
            searchByList={this.state.searchByList}
            dealerPremiumTypeList={this.state.dealerPremiumTypeList}
            optionChange={this.handleOptionChange}
            onSubmitFilter={this.submitFilterForm}
          />
          <div className="clearfix">
            <SearchResult
              pageloading={this.state.pageloading}
              loading={this.state.loading}
              dealerListData={this.state.dealerListData}
              statusClick={this.handleStatusClick}
              dealerCountStatusDetail={this.state.dealerCountStatusDetail}
              filterData={this.state.filterData}
              salesuserlist={this.getDealerList}
              salesusercount={this.getDealerCountStatusDetail}
              pageLoadingActivate={this.pageLoadingActivate}
            />
            <CSVLink
              asyncOnClick={true}
              className="hidden"
              ref={this.csvLink}
              data={this.state.mappingCsvData ? this.state.mappingCsvData : []}
              filename={`dealer-list-${Math.round(+new Date() / 1000)}.csv`}
              data-interception="off"
            >
              {this.props.t('Listing.Search_Result.Export_Data')}
            </CSVLink>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData }),
  };
};
export default withTranslation('sales_support_users')(connect(mapStateToProps, mapDispatchToProps)(SalesSupportUsersList));
