import axios from 'axios';
import { API_URL, APIV } from './../config/constant';
import secureStorage from './../config/encrypt';
import { toast } from 'react-toastify';

const Axios = axios.create();

let isRefreshing = false;
let failedQueue = [];
const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

axios.interceptors.response.use(
    async (response) => {
        if (response.data && response.data.status != 200) {
            console.error(`${response.data.status} - ${response.data.message}`, response.data)
            // if(response.data.status == 400)
            //     window.commonErrorToast(response.data.message)
            //     toast.error(`${response.data.status} - ${response.data.message}`);
        }
        return response;
    },
    async (error) => {
        // handle error
        if (error.response) {
            const originalRequest = error?.config;
            console.error(`${error.response.status} - ${error.response.statusText}`, error.response)
            if (error.response.status == 503) {
                // toast.error(`${error.response.status} - ${error.response.statusText}`);
            } else if (error.response.status == 401) {
                // If a token refresh is already in process.
                if (isRefreshing) {
                    // Create a new promise and push it into the failedQueue along with the original request.
                    // We use the original request to later retry this failed request.
                    return new Promise((resolve, reject) => {
                        failedQueue.push({ resolve, reject, originalRequest });
                    })
                        .then((token) => {
                            // Once the token is refreshed, update the Authorization header.
                            originalRequest.headers["Authorization"] = token;
                            // Retry the original request.
                            return Axios(originalRequest);
                        })
                        .catch((err) => {
                            // If token refresh fails, reject the promise.
                            return Promise.reject(err);
                        });
                }
                // Flag to indicate that this request has been retried.
                originalRequest._retry = true;
                // Flag to indicate a token refresh is in progress.
                isRefreshing = true;
                // Object to store the new refresh token.
                let refresh_token_data = {};
                    try {
                        // Attempt to refresh the token.
                        refresh_token_data = await refreshToken();

                        // Process the queue of failed requests.
                        processQueue(null, refresh_token_data.token);

                        // Update the Authorization header with the new token.
                        originalRequest.headers["Authorization"] = refresh_token_data.token;

                        // Retry the original request with the new token.
                        return Axios(originalRequest);

                    } catch (err) {
                        // If token refresh fails, clear the queue and redirect to logout.
                        processQueue(err, null);
                        window.location.href = "/logout";
                        return Promise.reject(err);
                    } finally {
                        // Reset the flag to indicate that the token refresh has completed.
                        isRefreshing = false;
                    }
            }
        }

        return error.response;
    });

window.commonErrorToast = function (error) {
    if(typeof error == "string") toast.error(error);
    else if(error.message) toast.error(error.message);
}
// export default {//need to remove
//     getMasters(list) {
//         return this.get("/core/commonservice/master", { master: list });
//     },
//     getToken() {
//         let fetchHeader = (secureStorage.getItem('loginUserInfo'))
//             ? secureStorage.getItem('loginUserInfo')
//             : {};
//         return (fetchHeader && fetchHeader.data && fetchHeader.data.token)
//             ? fetchHeader.data.token : null;
//     },
//     delete(URL, body, headers) {
//         headers = headers || {};
//         if(APIV) headers['apiv'] = APIV;
//         headers['Authorization'] = this.getToken();
//         headers['Accept-Language'] = secureStorage.getItem('lang');
//         URL = (URL[0] == '/') ? URL.substr(1) : URL;
//         return axios.delete(`${API_URL.GETWAY_API}${URL}`, body, {headers})
//     },
//     post(URL, body, headers) {
//         headers = headers || {};
//         if(APIV) headers['apiv'] = APIV;
//         headers['Authorization'] = this.getToken();
//         headers['Accept-Language'] = secureStorage.getItem('lang');
//         URL = (URL[0] == '/') ? URL.substr(1) : URL;
//         return axios.post(`${API_URL.GETWAY_API}${URL}`, body, {headers})
//     },
//     put(URL, body, headers) {
//         headers = headers || {};
//         if(APIV) headers['apiv'] = APIV;
//         headers['Authorization'] = this.getToken();
//         headers['Accept-Language'] = secureStorage.getItem('lang');
//         URL = (URL[0] == '/') ? URL.substr(1) : URL;
//         return axios.put(`${API_URL.GETWAY_API}${URL}`, body, {headers})
//     },
//     get(URL, query, headers) {
//         headers = headers || {};
//         if(APIV) headers['apiv'] = APIV;
//         headers['Authorization'] = this.getToken();
//         headers['Accept-Language'] = secureStorage.getItem('lang');
//         URL = (URL[0] == '/') ? URL.substr(1) : URL;
//         return axios.get(`${API_URL.GETWAY_API}${URL}`,
//             { params: query, headers })
//     }
// }
const MasterapiService = {
    getMasters(list) {
        return this.get("/core/commonservice/master", { master: list });
    },
    getToken() {
        let fetchHeader = (secureStorage.getItem('loginUserInfo'))
            ? secureStorage.getItem('loginUserInfo')
            : {};
        return (fetchHeader && fetchHeader.data && fetchHeader.data.token)
            ? fetchHeader.data.token : null;
    },
    delete(URL, body, headers) {
        headers = headers || {};
        if(APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang');
        URL = (URL[0] == '/') ? URL.substr(1) : URL;
        return axios.delete(`${API_URL.GETWAY_API}${URL}`, body, {headers})
    },
    post(URL, body, headers) {
        headers = headers || {};
        if(APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang');
        URL = (URL[0] == '/') ? URL.substr(1) : URL;
        return axios.post(`${API_URL.GETWAY_API}${URL}`, body, {headers})
    },
    put(URL, body, headers) {
        headers = headers || {};
        if(APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang');
        URL = (URL[0] == '/') ? URL.substr(1) : URL;
        return axios.put(`${API_URL.GETWAY_API}${URL}`, body, {headers})
    },
    get(URL, query, headers) {
        headers = headers || {};
        if(APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang');
        URL = (URL[0] == '/') ? URL.substr(1) : URL;
        return axios.get(`${API_URL.GETWAY_API}${URL}`,
            { params: query, headers })
    }
}

async function refreshToken() {
    try {
        let loginUserInfo = (secureStorage.getItem('loginUserInfo'))
            ? secureStorage.getItem('loginUserInfo')
            : null;
        if (loginUserInfo) {
            let url = `${API_URL.GETWAY_API}account/user/refresh_token`;
            const { data } = await Axios.post(url, { refresh_token: loginUserInfo.data.refresh_token }, {
                headers: { 'Authorization': loginUserInfo.data.token }
            });
            if (data && data.status === 200) {
                secureStorage.setItem('loginUserInfo', data);
                return data.data;
            } else {
                window.location.href = '/logout';
            }
        } else {
            window.location.href = '/logout';
        }

    } catch (error) {
        console.error("Error refreshing token: ", error);
        //  window.location.href = '/logout';
        return null;
    }
}

export default MasterapiService;